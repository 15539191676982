import ready from "../supports/ready";

export default async function () {
    await ready();

    const spotlight = document.getElementById("spotlight");
    const main = document.getElementById("spotlightcontainer");

    if (spotlight) {

        main.addEventListener("mousemove", moveSpotlight);
        main.addEventListener("touchmove", moveSpotlight);

        function moveSpotlight(e) {
            let x, y;
            let boundingBox = main.getBoundingClientRect();

            x = e.clientX - boundingBox.left;
            y = e.clientY - boundingBox.top;

            spotlight.style.left = x + "px";
            spotlight.style.top = y + "px";
        }
        requestAnimationFrame(moveSpotlight);
    }
};