import query from '../supports/query';
import ready from '../supports/ready';

import { Modal } from "bootstrap";

export default async function () {
    await ready();

    let modal = new Modal(document.createElement('DIV'));
    modal._initializeBackDrop();

    query('[data-bs-target][data-bs-toggle="collapse"][data-handle\\:collapse]').map((el) => {
        let selector = el.getAttribute('data-handle\:collapse');
        let target = selector ? el.querySelector(selector) : el;
        let collapse = query(el.dataset.bsTarget);

        if (collapse.length === 1) {
            collapse = collapse[0];
        } else {
            return;
        }
        
        collapse.addEventListener('hide.bs.collapse', () => {
            modal._backdrop.hide();
            collapse.parentElement.parentElement.style.removeProperty('z-index');
            target.innerText = el.dataset.onHidden;
        });
        
        collapse.addEventListener('show.bs.collapse', () => {
            modal._backdrop.show();
            collapse.parentElement.parentElement.style.zIndex = '10000';
            target.innerText = el.dataset.onShown;
        });
    });
}
