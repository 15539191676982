
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import ready from "../supports/ready";
import query from "../supports/query";

async function invokeMatterportScroll() {
    await ready();
    
    const matterportVideo = query('.js-scrub-video');

    if (matterportVideo.length === 0) {
        return;
    }

    gsap.registerPlugin(ScrollTrigger);

    matterportVideo[0].pause();
    matterportVideo.currentTime = 0;
    

    gsap.to('.js-scrub-video', {
        // ! TODO fix other browsers :D & no magic numbers
        currentTime: 7,
        scrollTrigger: {
            trigger: '.matterport',
            toggleActions: "restart complete reverse reset",
            start: '-50% top',
            end: "50% -50%",
            scrub: 1.4,
            // markers: true,
        },
        
    })

}

// Export Module
export default invokeMatterportScroll;