import ready from "../supports/ready";

export default async function () {
    await ready();


    const contactPoint = document.querySelectorAll('.contact__point');

    if (contactPoint) {

        contactPoint.forEach((ctpt) => {
            let btn = ctpt.querySelector('.button');
            let textField = ctpt.querySelector('.text_field');

            let textFieldHeight = textField.scrollHeight;
            let textFieldWidth = textField.scrollWidth;
            /*btn.addEventListener('mouseover', () => {
                textField.style.setProperty("--textfieldHeight", textFieldHeight + "px");
            });*/
            btn.addEventListener('click', () => {

                if (ctpt.classList.contains('show')) {
                    ctpt.classList.remove('show');
                } else {
                    const contactPoint = document.querySelectorAll('.contact__point.show');

                    function active(){
                        ctpt.classList.add('show');
                    }
                    if (contactPoint.length == 0) {
                        ctpt.style.setProperty("--textfieldWidth", textFieldWidth + "px"),
                            textField.style.setProperty("--textfieldHeight", textFieldHeight + "px"),
                            active();
                        return;
                    }
                    ctpt.style.setProperty("--textfieldWidth", textFieldWidth + "px"),
                        textField.style.setProperty("--textfieldHeight", textFieldHeight + "px"),

                        contactPoint.forEach((transition) => {
                            let listener = () => {                                
                                contactPoint.forEach((points) => {
                                    points.classList.remove('show');
                                });

                                setTimeout(
                                    active, 100
                                )
                                
                                transition.removeEventListener('transitionend', listener);
                            }

                            transition.addEventListener('transitionend', listener);
                        });
                }
            });
        });

        document.addEventListener('click', function (event) {
            if (event.target.closest('.contact__point.show')) return
            contactPoint.forEach((ctp) => {
                ctp.classList.remove('show');
            })
        });
    }
}