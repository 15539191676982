import { click, scroll } from "../supports/events";
import ready from "../supports/ready";
import select from "../supports/select";

/**
 * Export ScrollTop Plugin
 * @returns 
 */
export default async function () {
    await ready();
    
    // Has ScrollTop
    let scrollTop = select('.scroll-top');
    if (!scrollTop) {
        return;
    }

    // Add Event Listener
    click(scrollTop, ev => {
        ev.preventDefault();
        scrollTop?.blur();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });

    // Toggle ScrollTop button on scroll
    scroll(window, () => {
        if (document.documentElement.scrollTop > 300) {
            scrollTop?.classList.add('scroll-top-visible');
        } else {
            scrollTop?.classList.remove('scroll-top-visible');
        }
    });
};
