
import invokeBootstrap from "./plugins/bootstrap";
import invokeKeenSlider from "./plugins/_keen-slider";
import invokeLightbox from "./plugins/lightbox";
import invokeScrollTop from "./plugins/scroll-top";

import invokeMagicNavbar from "./plugins/magic-navbar";
import invokeIntersection from "./plugins/intersection";
import invokeEvents from "./plugins/events";
import invokeFade from "./plugins/fade";
import invokeHeroParallax from "./plugins/hero-parallax";
import invokeKeyNumbers from "./plugins/keynumbers";
import invokeMatterPortScroll from "./plugins/matterport-scroll";
import invokeOSM from "./plugins/osm";
import invokeScrollAnimation from "./plugins/scroll-animation";
import invokeGridAnimation from "./plugins/gridanimation";
import invokeToggleButtons from "./plugins/toggle-buttons";
import invokeNoJs from "./plugins/nojs";
import invokeFirmenCTA from "./plugins/firmencta";
import invokeSpotlight from "./plugins/spotlight";
import invokeTeam from "./plugins/team";
import invokeScrollTextImageAnimation from "./plugins/textimagescrollanimation";
import invokeCountdown from "./plugins/countdown"

// Invoke NFCore Plugins
invokeBootstrap();
invokeKeenSlider();
invokeLightbox();
invokeMagicNavbar();
invokeScrollTop();

invokeIntersection();
invokeMagicNavbar();
invokeEvents();
invokeFade();
invokeHeroParallax();
invokeKeyNumbers();
invokeMatterPortScroll();
invokeOSM();
invokeScrollAnimation();
invokeGridAnimation();
invokeToggleButtons();
invokeNoJs();
invokeFirmenCTA();
invokeSpotlight();
invokeTeam();
invokeScrollTextImageAnimation();
invokeCountdown();