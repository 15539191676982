import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import query from "../supports/query";
import ready from "../supports/ready";

export default async function () {
  await ready();

  if (query('.scrollanimation').length === 0) {
    return;
  }

  gsap.registerPlugin(ScrollTrigger);

  let tl = gsap.timeline({
    
    scrollTrigger: {
      trigger: ".hero--shw",
      pin: true, // pin the trigger element while active
      start: "top top", // when the top of the trigger hits the top of the viewport
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    }
  });

  // add animations and labels to the timeline
  tl.addLabel("start")
    .to(".hero--shw .hero__fg", {
      scale: 1.025,
      duration: 1
    }, "<")
    .to(".hero--shw .hero__bg", {
      scale: 1.2,
      duration: 1.2
    }, "<")
    .to(".hero--shw .hero__txt", {
      y: -100,
      opacity: 1,
      duration: 1.25
    }, "=+.4")


  let tlflb = gsap.timeline({
    scrollTrigger: {
      trigger: ".hero--flb",
      pin: true, // pin the trigger element while active
      start: "top top", // when the top of the trigger hits the top of the viewport        
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    }
  });

  // add animations and labels to the timeline
  tlflb.addLabel("start")
    //.to(".hero--flb .hero__fg", {scale: 1.025, duration: 1}, "<")
    .to(".hero--flb .hero__bg", {
      scale: 1.15,
      duration: 1.25
    }, "<")
    .to(".hero--flb .hero__txt", {
      y: '-130vh',
      duration: 1.25
    }, "<")


  let tljhh = gsap.timeline({
    scrollTrigger: {
      trigger: ".hero--jhh",
      pin: true, // pin the trigger element while active      
      start: "top top", // when the top of the trigger hits the top of the viewport        
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    }
  });

  // add animations and labels to the timeline
  tljhh.addLabel("start")
    //.to(".hero--flb .hero__fg", {scale: 1.025, duration: 1}, "<")
    .to(".hero--jhh .hero__bg", {
      x: 60,
      duration: 1.25
    }, "<")
    .to(".hero--jhh .hero__txt", {
      y: '-130vh',
      duration: 1.25
    }, "<")

  let tlrcg = gsap.timeline({
    scrollTrigger: {
      trigger: ".hero--rcg",
      pin: true, // pin the trigger element while active      
      start: "top top", // when the top of the trigger hits the top of the viewport        
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    }
  });

  // add animations and labels to the timeline
  tlrcg.addLabel("start")
    //.to(".hero--flb .hero__fg", {scale: 1.025, duration: 1}, "<")
    .to(".hero--rcg .hero__bg", {
      scale: 1.05,
      duration: 1.25
    }, "<")
    .to(".hero--rcg .hero__txt", {
      y: -100,
      opacity: 1,
      duration: 1.25
    }, "<")


  // BMX //

  let tlbmx = gsap.timeline({
    scrollTrigger: {
      trigger: ".hero--bmx",
      pin: true,
      start: "top top",
      scrub: 1,
    }
  });

  tlbmx.addLabel("start")
    .to(".hero--bmx .hero__fg", {
      rotate: 4,
      x: 150,
      duration: 1.25
    }, "<")
    .to(".hero--bmx .hero__txt", {
      y: -100,
      opacity: 1,
      duration: 1.25
    }, "<")

  // moon //

  let tlmoon = gsap.timeline({
    scrollTrigger: {
      trigger: ".hero--moon",
      pin: true,
      start: "top top",
      scrub: 1,
    }
  });

  tlmoon.addLabel("start")
    .to(".hero--moon .hero__fg", {
      x: 150,
      y: 25,
      duration: 1.25
    }, "<")
    .to(".hero--moon .hero__txt", {
      y: -100,
      opacity: 1,
      duration: 1.25
    }, "<")

  // Singer //

  let tlsinger = gsap.timeline({
    scrollTrigger: {
      trigger: ".hero--singer",
      pin: true,
      start: "top top",
      scrub: 1,
    }
  });

  tlsinger.addLabel("start")
    .to(".hero--singer .hero__fg", {
      x: 50,
      y: -15,
      duration: 1.25
    }, "<")
    .to(".hero--singer .hero__txt *", {
      y: -100,
      opacity: 1,
      duration: 1.25
    }, "<")

  // Ring //

  let tlring = gsap.timeline({
    scrollTrigger: {
      trigger: ".hero--ring",
      pin: true,
      start: "top top",
      scrub: 1,
    }
  });

  tlring.addLabel("start")
    .to(".hero--ring .hero__fg", {
      rotate: 5,
      y: -25,
      duration: 1.5
    }, "<")
    .to(".hero--ring .hero__txt *", {
      y: -100,
      opacity: 1,
      duration: 1.25
    }, "<")

  // Image Grow //

  let tlimggrw = gsap.timeline({
    scrollTrigger: {
      trigger: ".imagegrow",
      pin: true,
      start: "-100",
      scrub: 1,
    }
  });

  tlimggrw.addLabel("start")
    .to(".imagegrow .imagegrow__text", {
      y: -450,
      duration: 1.25
    }, "<")
    .to(".imagegrow .imagegrow__img", {
      width: "calc(100% - 8rem)",
      duration: 1.25,
      delay: .125
    }, "<")



  gsap.registerPlugin(ScrollTrigger);

  //video

  // const coolVideo = document.querySelector("#video video");
  // if (coolVideo != null) {
  //   let tlvideo = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: "video",
  //       start: "top top",
  //       scrub: true,
  //       pin: true,
  //     }
  //   });

  //   // wait until video metadata is loaded, so we can grab the proper duration before adding the onscroll animation. Might need to add a loader for loonng videos
  //   coolVideo.onloadedmetadata = function () {
  //     tlvideo.addLabel("video")
  //         .to(coolVideo, { currentTime: coolVideo.duration }, "<")
  //         .to("#video h2", {y:-100, opacity:1, duration: 1}, "<")
  //   };
  // }


  // // Dealing with devices
  // function isTouchDevice() {
  //   return (
  //     "ontouchstart" in window ||
  //     navigator.maxTouchPoints > 0 ||
  //     navigator.msMaxTouchPoints > 0
  //   );
  // }
  // if (isTouchDevice()) {
  //   coolVideo.play();
  //   coolVideo.pause();
  // }


}
