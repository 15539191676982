

type Elements = Document | HTMLElement | Window;
type Targets = Elements | ArrayLike<Elements> | Iterable<Elements>;

/**
 * Add event listeners on targets
 * @param targets 
 * @param events 
 * @param callback 
 * @returns
 */
function on(targets: Targets, events: string | string[], callback: EventListener) {
    if (typeof events == 'string') {
        events = [events];
    }
    
    if (targets instanceof Document || targets instanceof  HTMLElement || targets instanceof  Window) {
        targets = [targets];
    }

    Array.from(targets, target => {
        Array.from(events, event => {
            target.addEventListener(event, callback);
        });
    });
}

/**
 * Add Click Event
 * @param targets 
 * @param callback 
 * @returns
 */
function click(targets: Targets, callback: EventListener) {
    on(targets, 'click', callback);
}

/**
 * Add Scroll Event
 * @param targets 
 * @param callback 
 * @returns
 */
function scroll(targets: Targets, callback: EventListener) {
    on(targets, 'scroll', callback);
}

// Export Module
export { on, click, scroll };
