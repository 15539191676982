export default function startCountdown() {
  const countdown = document.querySelector("#countdown");
  const daysElement = document.getElementById("countdown_days");
  const hoursElement = document.getElementById("countdown_hours");
  const minutesElement = document.getElementById("countdown_minutes");
  const secondsElement = document.getElementById("countdown_seconds");

  const previousDaysElement = document.getElementById("countdown_days_previous");
  const previousHoursElement = document.getElementById("countdown_hours_previous");
  const previousMinutesElement = document.getElementById("countdown_minutes_previous");
  const previousSecondsElement = document.getElementById("countdown_seconds_previous");

  // page has no countdown
  if(!countdown) return; 

  const dateString = countdown.dataset.countdown;
  const date = parseInt(dateString);

  if (!daysElement) return;

  const endTime = new Date(date * 1000);

  function updateCountdown() {
    const now = new Date().getTime();

    if (now > endTime) {
        daysElement.style.opacity = "0";
        hoursElement.style.opacity = "0";
        minutesElement.style.opacity = "0";
        secondsElement.style.opacity = "0";
        clearInterval(interval);
        return;
    }

    const distance = endTime - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    const formattedDays = days < 10 ? '0' + days : days;
    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    // const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

    if (daysElement.innerText !== formattedDays.toString()) {
        triggerFade(daysElement, previousDaysElement, formattedDays);
    }
    if (hoursElement.innerText !== formattedHours.toString()) {
        triggerFade(hoursElement, previousHoursElement, formattedHours);
    }
    if (minutesElement.innerText !== formattedMinutes.toString()) {
        triggerFade(minutesElement, previousMinutesElement, formattedMinutes);
    }
    // if (secondsElement.innerText !== formattedSeconds.toString()) {
    //     triggerFade(secondsElement, previousSecondsElement, formattedSeconds);
    // }
  }

  function triggerFade(currentElement, previousElement, newValue) {
    if (currentElement.innerText !== newValue) {
        previousElement.innerText = currentElement.innerText;
        previousElement.style.opacity = "1";
        currentElement.style.opacity = "0";

        setTimeout(() => {
            currentElement.innerText = newValue;
            currentElement.style.opacity = "1";
            previousElement.style.opacity = "0";
        }, 500);
    }
  }

  const interval = setInterval(updateCountdown, 1000);
  updateCountdown();
}
