import { transform } from 'ol/proj';
import Map from 'ol/Map';
import Image from 'ol/layer/Image';
import Tile from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import ImageStatic from 'ol/source/ImageStatic';
import View from 'ol/View';

import query from "../supports/query";
import ready from "../supports/ready";

export default async function () {
    await ready();

    query('[data-map]').map(map => {
        let extent = [
            ...transform([parseFloat(map.dataset.mapLon), parseFloat(map.dataset.mapLat)], 'EPSG:4326','EPSG:3857'), 
            ...transform([parseFloat(map.dataset.mapLon), parseFloat(map.dataset.mapLat)], 'EPSG:4326','EPSG:3857')
        ];
        extent[2] += 128 * 2
        extent[3] += 112 * 2

        map.style.width = '100%';
        map.style.height = '100%';
        new Map({
            controls: [],
            layers: [
                new Tile({ 
                    source: new OSM({
                        url: map.dataset.mapSource
                    }) 
                }),
                new Image({
                    extent: extent,
                    source: new ImageStatic({
                        url: `${location.origin}/_assets/vite/imgs/icons/${map.dataset.mapFlag}.png`,
                        imageExtent: extent,
                        imageSize: [128 * 2, 112 * 2]
                    }),
                })
            ],
            interactions: [],
            target: map,
            view: new View({
                enableRotation: false,
                center: transform([parseFloat(map.dataset.mapLon), parseFloat(map.dataset.mapLat)], 'EPSG:4326','EPSG:3857'),
                zoom: 16,
                minZoom: 16,
                maxZoom: 16
            })
        });
    });
}
