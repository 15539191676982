import { falsy, truly } from "../supports/boolean";
import query from "../supports/query";
import ready from "../supports/ready";
import select from "../supports/select";

class Navbar {

    /**
     * Default Options
     *      stayOpen                Stay open when the user clicks outside of the navbar
     *      stickyTrack             Toggle visibility of sticky header on scroll
     *      trackOffset             Vertical Down / Up Offset until the pin-state changes
     *      trackTolerance          Scroll-Tolerance (Amount) until the pin-state toggles
     */
    static defaults = {
        stayOpen: false,
        stickyTrack: true,
        trackOffset: {
            down: 90,
            up: 0,
        },
        trackTolerance: {
            down: 0,
            up: 40
        }
    }

    /**
     * Already initialized Navbar instances.
     */
    static instances = new Map;

    /**
     * Get instance by navbar element.
     * @param {HTMLElement} el 
     * @returns {Navbar}
     */
    static getInstance(el) {
        if (!this.instances.has(el)) {
            throw new Error('The passed navbar element has not bee initialized yet.');
        } else {
            return this.instances.get(el);
        }
    }

    /**
     * Check if Navbar instance exists for passed navbar element.
     * @param {HTMLElement} el 
     * @returns {boolean}
     */
    static hasInstance(el) {
        return this.instances.has(el);
    }

    /**
     * Create a new Navbar instance from an navbar element.
     * @param {HTMLElement} el 
     * @param {Object} config 
     */
    constructor(root, config = {}) {
        if (!root || !(root instanceof HTMLElement)) {
            throw new Error('The passed argument is not a valid Navbar.');
        }
        if (Navbar.hasInstance(root)) {
            throw new Error('A Navbar instance has already been initialized for the passed element.');
        }
        Navbar.instances.set(root, this);

        // Set Instance Variables
        this.root = root;
        this.config = Object.assign({}, Navbar.defaults, config && typeof config === 'object' ? config : {});
        this.goBackButton = select('[data-action="goback"]', this.root);

        // Set Instance States
        this.baseHeight = this.root.offsetHeight;
        this.frozen = false;
        this.scrolled = false;
        this.scrollId = null;
        this.scrolledLast = 0;

        // Attach Window / Document Event
        window.addEventListener('resize', this.onResize.bind(this));
        document.addEventListener('click', this.onClickOutside.bind(this));

        // Attach GoBack Button
        if (this.goBackButton) {
            this.goBackButton.addEventListener('click', (event) => {
                let submenu = select('.navbar-subnav-container[aria-expanded="true"]');
                if (submenu) {
                    this.onToggleSubMenu(submenu.previousElementSibling, submenu, event);
                }
            });
        }

        // Attach Mobile / Tablet Menu Toggle Clicks
        query('[data-toggle="menu"]', this.root, (action) => {
            action.addEventListener('click', this.onToggleMainMenu.bind(this, action));
        });

        // Attach Submenu Toggle Clicks
        query('[data-submenu],[data-toggle="submenu"]', this.root, (action) => {
            let selector = action.dataset.submenu || action.dataset.target || false;
            let target = selector ? select(selector, this.root) : null;
            if (target || (target = select('.navbar-subnav-container', action.parentElement))) {
                action.addEventListener('click', this.onToggleSubMenu.bind(this, action, target));
            }
        });

        // Attach ScrollTracker for Headroom / Sticky Header
        if (this.config.stickyTrack) {
            this.scrolledLast = this.scrollTop;

            // Defer Event binding to handle browser-based scroll-position restore process
            setTimeout(() => {
                window.addEventListener('scroll', this.scrollTracker.bind(this), {
                    passive: true, 
                    capture: false 
                });
                this.trackerUpdate();
            }, 150);
        }
    }

    /**
     * Get ScrollTop Helper 
     */
    get scrollTop() {
        let scrollTop = window.scrollY || window.pageYOffset || 0;
        if (!scrollTop) {
            scrollTop = (document.documentElement || document.body).scrollTop;
        }
        return scrollTop;
    }


    /**
     * Check if current viewport could be desktop.
     */
    get isDesktop() {
        if (typeof this._desktopBreakpoint === 'undefined') {
            let temp = window.getComputedStyle(document.body).getPropertyValue('--bs-breakpoint-lg');
            if (typeof temp === 'string') {
                temp = parseInt(temp.trim(), 10);
            }
            if (typeof temp !== 'number') {
                temp = 1024;
            }
            this._desktopBreakpoint = temp;
        }
        return window.innerWidth >= this._desktopBreakpoint;
    }

    /**
     * Internal ScrollTracker
     */
    scrollTracker(event) {
        if (this.scrolled) {
            return;
        }
        this.scrolled = true;
        this.scrollId = requestAnimationFrame(this.trackerUpdate.bind(this));
    }

    /**
     * Tracker Update Handler
     */
    trackerUpdate() {
        let html = document.documentElement;
        let body = document.body;

        // Measure and Determine
        let scrollY = Math.round(this.scrollTop);
        let height = window.innerHeight || html.clientHeight || body.clientHeight;
        let scrollHeight =  Math.max(
            body.scrollHeight, html.scrollHeight,
            body.offsetHeight, html.offsetHeight,
            body.clientHeight, html.clientHeight
        );
        let direction = scrollY > this.scrolledLast ? 'down' : 'up';
        let distance = Math.abs(scrollY - this.scrolledLast);
        let isOutOfBounds = scrollY < 0 || scrollY + height > scrollHeight;
        let top = scrollY <= this.config.trackOffset[direction];
        let bottom = scrollY + height >= scrollHeight;
        let toleranceExceeded = distance > this.config.trackTolerance[direction];
        
        // Handle Update
        (() => {
            if (isOutOfBounds) {
                return;
            }
            if (this.frozen) {
                return;
            }

            if (top && !this.root.classList.contains('on-top')) {
                this.root.classList.add('on-top');
                this.root.classList.remove('not-on-top');
            } else if (!top && !this.root.classList.contains('not-on-top')) {
                this.root.classList.remove('on-top');
                this.root.classList.add('not-on-top');
            }

            if (direction === 'down' && !top && toleranceExceeded) {
                if (!this.root.classList.contains('unpinned')) {
                    this.root.classList.add('unpinned');
                    // this.root.style.height = '0px';
                }
                if (this.root.classList.contains('pinned')) {
                    this.root.classList.remove('pinned');
                }
            } else if ((direction === 'up' && toleranceExceeded) || top) {
                if (this.root.classList.contains('unpinned')) {
                    this.root.classList.remove('unpinned');
                }
                if (!this.root.classList.contains('pinned')) {
                    this.root.classList.add('pinned');
                    this.root.style.height = `${this.baseHeight}px`;
                }
            }
        })();
        
        // Set Circle
        this.scrolledLast = scrollY;
        this.scrolled = false;
    }

    /**
     * Determine height of an invisible element.
     * @param {HTMLElement} element 
     * @param {HTMLElement} parent 
     * @returns {number}
     */
    determineHeight(element, parent) {
        let clone = element.cloneNode(true);

        clone.style.height = 'auto';
        clone.style.minHeight = 'none';
        clone.style.overflow = 'visible';
        clone.style.position = 'absolute';
        clone.style.visibility = 'hidden';
        clone.style.opacity = 0;

        parent.appendChild(clone);
        const height = clone.offsetHeight;
        parent.removeChild(clone);

        return height;
    }

    /**
     * Detect click outside and handle accordingly.
     * @param {Event} event 
     * @returns 
     */
    onClickOutside(event) {
        if (this.config.stayOpen) {
            return;
        }

        // No Target
        let target = event.target;
        if (!target) {
            return;     // Nothing to do
        }

        // Current Menu is not active
        if (!this.root.classList.contains('menu-active') && !this.root.classList.contains('submenu-active')) {
            return;     // Nothing to do
        }

        // Click appeared within the current active menu
        let closest = target.closest('nav.navbar');
        if (closest && closest === this.root) {
            return;     // Nothing to do
        }

        const inactive = (el) => {
            el.classList.remove('active');
        };

        // Hide GoBackButton
        if (this.goBackButton && this.goBackButton.classList.contains('visible')) {
            this.goBackButton.classList.remove('visible');
            this.goBackButton.classList.add('invisible');
        }

        // Reset Main Menu Togglers
        query('[data-toggle="menu"].active', this.root, inactive);
        if (this.root.classList.contains('menu-active')) {
            this.closeMenu(null);
        }

        // Reset Sub Menu Togglers
        query('[data-submenu].active,[data-toggle="submenu"].active', this.root, inactive);
        if (this.root.classList.contains('submenu-active')) {
            this.closeSubMenu(select('.navbar-subnav-container[aria-expanded="true"]', this.root));
        }
    }

    /**
     * Event Listener to window resize
     * @param {Event} event 
     * @returns
     */
    onResize(event) {
        let promises = [];
        let resizeRequired = false;
        const inactive = (el) => {
            el.classList.remove('active');
            resizeRequired = false;
        };

        // Resize Navbar
        if (this.isDesktop && this.baseHeight !== 120) {
            this.baseHeight = 120;
            resizeRequired = true;
        } else if (!this.isDesktop && this.baseHeight !== 80) {
            this.baseHeight = 80;
            resizeRequired = true;
        }

        // Unfreeze Instance
        this.frozen = false;

        // Hide GoBackButton
        if (this.goBackButton && this.goBackButton.classList.contains('visible')) {
            this.goBackButton.classList.remove('visible');
            this.goBackButton.classList.add('invisible');
        }

        // Reset Main Menu Togglers
        query('[data-toggle="menu"].active', this.root, inactive);
        if (this.root.classList.contains('menu-active')) {
            promises.push(this.closeMenu(null));
        }

        // Reset Sub Menu Togglers
        query('[data-submenu].active,[data-toggle="submenu"].active', this.root, inactive);

        //@todo QuickFick to toggle static height
        if (resizeRequired) {
            if (promises.length === 0) {
                this.root.style.height = `${this.baseHeight}px`;
            }
        }
    }

    /**
     * Toggle Main Menu on Mobile & Tablet
     * @param {HTMLElement} action Target used to call this method.
     * @param {Event} event Click Event
     */
    onToggleMainMenu(action, event) {
        if (event) {
            event.preventDefault();
        }

        if (!this.root.classList.contains('menu-active')) {
            this.frozen = true;
            this.openMenu(action).then((btn) => {
                if (btn) {
                    btn.classList.add('active');
                }
            });
        } else {
            if (this.goBackButton && this.goBackButton.classList.contains('visible')) {
                this.goBackButton.classList.remove('visible');
                this.goBackButton.classList.add('invisible');
            }

            this.closeMenu(action).then((btn) => {
                if (btn) {
                    btn.classList.remove('active');
                }
            });
            this.frozen = false;
        }
    }

    /**
     * Open Main Menu on Mobile & Tablet
     * @param {*} action 
     * @returns {Promise}
     */
    openMenu(action) {
        this.root.classList.add('menu-active');
        this.root.style.height = `${window.innerHeight}px`;

        // Disable Body scrolling on non-Desktop devices.
        if (!this.isDesktop) {
            document.body.style.overflow = 'hidden';
        }

        // Close Search
        if (!this.isDesktop && this.root.classList.contains('submenu-active')) {
            let submenu = select('#navbar-subnav-search[aria-expanded="true"]');
            if (submenu) {
                return new Promise(resolve => {
                    let async = this.closeSubMenu(select('[data-submenu="#navbar-subnav-search"]', this.root), submenu);
                    async.then(([btn, submenu]) => {
                        if (btn && submenu) {
                            btn.classList.remove('active');
                            btn.setAttribute('aria-expanded', 'false');
                        }
                        this.openMenu(action).then((args) => resolve(args));
                    });
                });
            }
        }

        // Return when finished
        return new Promise(resolve => {
            const toResolve = () => {
                if (this.root.offsetHeight >= window.innerHeight) {
                    resolve(action);
                } else {
                    setTimeout(toResolve, 100);
                }
            };
            setTimeout(toResolve, 100);
        });
    }

    /**
     * Close Main Menu on Mobile & Tablet
     * @param {*} action 
     * @returns {Promise}
     */
    closeMenu(action) {
        this.root.classList.remove('menu-active');
        this.root.style.height = `${this.baseHeight}px`;

        // Re-Enable Body scrolling if disabled
        if ((document.body.style.overflow || '').toLowerCase() === 'hidden') {
            document.body.style.removeProperty('overflow');
        }

        // Close SubMenus
        this.closeSubMenu(null, null);

        // Return when finished
        return new Promise(resolve => {
            const toResolve = () => {
                if (this.root.offsetHeight <= this.baseHeight) {
                    resolve(action);
                } else {
                    setTimeout(toResolve, 100);
                }
            };
            setTimeout(toResolve, 100);
        });
    }

    /**
     * Toggle Sub Menu Container
     * @param {HTMLElement} action Target used to call this method.
     * @param {HTMLElement} submenu Desired Submenu to toggle.
     * @param {Event} event Click Event
     */
    onToggleSubMenu(action, submenu, event) {
        if (event) {
            event.preventDefault();
        }
        if (falsy(submenu.getAttribute('aria-expanded') || '0')) {
            this.frozen = true;
            this.openSubMenu(action, submenu).then(([btn, submenu]) => {
                if (btn && submenu) {
                    btn.classList.add('active');
                    btn.setAttribute('aria-expanded', 'true');
                }
            });
        } else {
            if (this.goBackButton && this.goBackButton.classList.contains('visible')) {
                this.goBackButton.classList.remove('visible');
                this.goBackButton.classList.add('invisible');
            }

            this.closeSubMenu(action, submenu).then(([btn, submenu]) => {
                if (btn && submenu) {
                    btn.classList.remove('active');
                    btn.setAttribute('aria-expanded', 'false');
                }
            });
            this.frozen = false;
        }
    }

    /**
     * Open SubMenu
     * @param {HTMLElement} action Target used to call this method.
     * @param {HTMLElement} submenu Desired Submenu to toggle.
     * @param {Event} event Click Event
     */
    openSubMenu(action, submenu) {
        if (this.isDesktop) {
            if (submenu.classList.contains('subnav-small')) {
                this.root.style.height = '320px';
            } else {    
                this.root.style.height = '450px';
            }
        } else {
            if (this.root.classList.contains('menu-active') && submenu.classList.contains('subnav-small')) {
                return new Promise(resolve => {
                    let async = this.closeMenu(select('[data-toggle="menu"].active', this.root));
                    async.then((btn) => {
                        if (btn) {
                            btn.classList.remove('active');
                        }
                        this.openSubMenu(action, submenu).then((args) => resolve(args));
                    });
                });
            }
            if (action.dataset.submenu.indexOf('search') > 0) {
                this.root.style.height = `${window.innerHeight/2}px`;
            } else {
                this.root.style.height = `${window.innerHeight}px`;
            }
        }

        // SubMenu Class
        if (!this.root.classList.contains('submenu-active')) {
            this.root.classList.add('submenu-active');
        }

        // Show Backdrop
        let backdrop = document.querySelector('.navbar-backdrop');
        if (!backdrop) {
            backdrop = document.createElement('DIV');
            backdrop.className = 'backdrop navbar-backdrop';
            document.body.appendChild(backdrop);
        }
        
        if (!backdrop.classList.contains('active')) {
            setTimeout(() => backdrop.classList.add('active'), 100);
        }

        // Hide other Submenus
        query('.navbar-subnav-container[aria-expanded="true"],[data-submenu],[data-toggle="submenu"]', (el) => {
            el.setAttribute('aria-expanded', 'false');
            el.classList.remove('active');
            if (el.parentElement === el.closest('li')) {
                el.parentElement.classList.remove('active');
                el.closest('ul').classList.remove('is-visible');
            }
        });

        // Show current Submenu
        submenu.setAttribute('aria-expanded', 'true');
        if (submenu.parentElement === submenu.closest('li')) {
            submenu.parentElement.classList.add('active');
            submenu.closest('ul').classList.add('is-visible');
        }

        // Go Back
        if (this.root.classList.contains('menu-active') && this.goBackButton) {
            this.goBackButton.classList.remove('invisible');
            this.goBackButton.classList.add('visible');
        }

        // Return when finished
        return new Promise(resolve => {
            let lastHeight = 0;
            const toResolve = () => {
                if (this.root.offsetHeight !== lastHeight) {
                    resolve([action, submenu]);
                } else {
                    lastHeight = this.root.offsetHeight;
                    setTimeout(toResolve, 100);
                }
            };
            setTimeout(toResolve, 100);
        });
    }

    /**
     * Close SubMenu
     * @param {HTMLElement} action Target used to call this method.
     * @param {HTMLElement} submenu Desired Submenu to toggle.
     * @param {Event} event Click Event
     */
    closeSubMenu(action, submenu) {
        if (this.root.classList.contains('submenu-active')) {
            this.root.classList.remove('submenu-active');
        }
        if (!this.root.classList.contains('menu-active')) {
            this.root.style.height = `${this.baseHeight}px`;
        }

        // Hide Backdrop
        if (!this.root.classList.contains('menu-active')) {
            let backdrop = document.querySelector('.navbar-backdrop');
            if (backdrop) {
                backdrop.classList.remove('active');
                setTimeout(() => {
                    if (!backdrop.classList.contains('active')) {
                        backdrop.remove();
                    }
                }, 250);
            }
        }

        // Hide all Submenus
        query('.navbar-subnav-container[aria-expanded="true"]', (el) => {
            el.setAttribute('aria-expanded', 'false');
            if (el.parentElement === el.closest('li')) {
                el.parentElement.classList.remove('active');
                el.closest('ul').classList.remove('is-visible');
            }
        });

        // Return when finished
        return new Promise(resolve => {
            let lastHeight = 0;
            const toResolve = () => {
                if (this.root.offsetHeight !== lastHeight) {
                    resolve([action, submenu]);
                } else {
                    lastHeight = this.root.offsetHeight;
                    setTimeout(toResolve, 100);
                }
            };
            setTimeout(toResolve, 100);
        });
    }
}


// Export Module
export default async function () {
    await ready();

    const navbar = select('[data-handle="navbar"]');
    if (!navbar) {
        return;
    }
    if (Navbar.hasInstance(navbar)) {
        return;
    }

    new Navbar(navbar, {
        stickyTrack: truly(navbar.dataset.headroom || '0')
    });
};
