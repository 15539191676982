
/**
 * Throttle a Function
 * @param {Function} callback The desired function to call.
 * @param {number} limit The amount of milliseconds the function should be called.
 * @returns 
 */
function throttle(callback: () => void, limit: number): () => void {
    let waiting = false;

    return function () {
        if (!waiting) {
            waiting = true;
            callback.apply(globalThis, Array.from(arguments) as any);
            setTimeout(() => waiting = false, limit);
        }
    }
}

// Export Function
export default throttle;
