import query from "../supports/query";
import ready from "../supports/ready";

function inViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && 
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) 
    );
}

function countUp(el, count) {
    let seconds = 2;
    if (count < seconds) {
        return;
    }

    el.innerText = '0';

    let step = Math.ceil(count / (seconds * 10));
    let current = 0;
    let timeout = () => {
        current = current + step;
        el.innerText = current > count ? count.toString() : current.toString();
        if (current < count) {
            setTimeout(timeout, 100);
        }
    };
    setTimeout(timeout, 100);
}

export default async function () {
    await ready();

    function onView(keyNumber) {
        if (!keyNumber.classList.contains('done')) {
            countUp(keyNumber, parseInt(keyNumber.dataset.keyNumber));
            keyNumber.classList.add('done');
        }
    }

    query('[data-key-number]').map(keyNumber => {
        setTimeout(() => {
            if (inViewport(keyNumber)) {
                onView(keyNumber);
            } else {
                keyNumber.innerText = '0';
                    const handler = function() {
                        onView(keyNumber);
                        window.removeEventListener('scroll', handler);
                    };
                    window.addEventListener('scroll', handler);
            }
        }, 750);
    });
}