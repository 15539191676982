import query from "../supports/query";
import ready from "../supports/ready";

import { 
    Popover,
    Tooltip 
} from "bootstrap";

/**
 * Export Bootstrap Plugin
 * @returns 
 */
export default async function () {
    await ready();

    // Initialize Popovers
    query('[data-bs-toggle="popover"][data-toggle="popover"]', (el) => {
        return Popover.getOrCreateInstance(el);
    });

    // Initialize Tooltips
    query('[data-bs-toggle="tooltip"][data-toggle="tooltip"]', (el) => {
        return Tooltip.getOrCreateInstance(el);
    });
};
