import query from "../supports/query";
import ready from "../supports/ready";

import { Collapse } from "bootstrap";

function applyQuery(form) {
    const searchElement = form.querySelector('[name="search"]');
    if (searchElement == null || searchElement.value.trim() !== '')
        return;

    const query = new URLSearchParams(window.location.search);
    if (query.has('event'))
        searchElement.value = query.get('event');
}

export default async function () {
    await ready();

    const form = document.querySelector('[data-handle="events"]');
    if (!form) {
        return;
    }

    applyQuery(form);

    const list = document.querySelector(form.dataset.target);
    if (!list) {
        return;
    }

    const showMoreButton = document.querySelector('[data-event-more]');
    function filterEventsList(countOnly = false) {
        const selectedLocations = Array.from(form.querySelectorAll('[name="locations[]"]:checked'), (i) => i.value);
        const selectedCategories = Array.from(form.querySelectorAll('[name="category[]"]:checked'), (i) => i.value);
        const selectedStartDate = form.querySelector('[name="datetime"]:checked') ? 
            form.querySelector('[name="datetime"]:checked').value :
            0;
        const searchValue = form.querySelector('[name="search"]') ? 
            form.querySelector('[name="search"]').value.trim().toLowerCase() :
            '';

        const startAt = new Date();
        if (selectedStartDate !== 0) {
            let [month, year] = selectedStartDate.split('-').map(i => parseInt(i, 10));
            if (startAt.getMonth() !== month || startAt.getFullYear() !== year) {
                startAt.setDate(1);
                startAt.setMonth(month - 1);
                startAt.setYear(year);
            }
        }
        
        const events = list.querySelectorAll('[data-event]');
        const currentDate = new Date;
        const currentStamp = ('0' + (currentDate.getMonth()+1)).slice(-2) + '-' + currentDate.getFullYear();
        let count = 0;
        for (const event of events) {
            let dataset;
            if (!isNaN(parseInt(event.dataset.event))) {
                dataset = {
                    location: event.dataset.event
                }
            } else {
                dataset = JSON.parse(event.dataset.event);
            }

            if (!countOnly) {
                event.style.display = 'none';
                event.dataset.visible = '0';
            }

            // Different Location
            if (selectedLocations.indexOf(dataset.location) < 0) {
                continue;
            }

            // Different Category
            if (selectedCategories.length > 0 && selectedCategories.indexOf(dataset.category) < 0) {
                continue;
            }

            // Different Date
            if (selectedStartDate !== 0 && selectedStartDate !== currentStamp && startAt) {
                if (dataset.dates.length <= 1) {
                    if (dataset.firstDate < startAt.getTime()) {
                        continue;
                    }
                } else {
                    let ct = dataset.dates.filter(date => date >= startAt.getTime());
                    if (ct.length === 0) {
                        continue;
                    }
                }
            }

            // Check Search Value
            if (searchValue.length > 0) {
                let title = dataset.title.trim().toLowerCase();
                let subtitle = dataset.subtitle.trim().toLowerCase();

                if (title.indexOf(searchValue) < 0 && subtitle.indexOf(searchValue) < 0) {
                    continue;
                }
            }

            // Show Event
            if (!countOnly) {
                if (form.querySelector('[data-event-count]')) {
                    if (count < 30) {
                        event.style.removeProperty('display');
                    }
                } else {
                    event.style.removeProperty('display');
                }
                event.dataset.visible = '1';
            }
            count++
        }
        
        if (!countOnly && showMoreButton) {
            if (count > 30) {
                showMoreButton.style.removeProperty('display');
            } else {
                showMoreButton.style.display = 'none';
            }
            let empty = document.querySelector('.js-eventsempty');
            if (empty) {
                empty.hidden = count !== 0;
            }
        }
        return count;
    }
    filterEventsList();

    if (showMoreButton) {
        showMoreButton.addEventListener('click', (ev) => {
            ev.preventDefault();
            Array.from(list.querySelectorAll('[data-event][data-visible="1"]'), (el) => {
                el.style.removeProperty('display');
            });
            showMoreButton.style.display = 'none';
        });
    }

    let counter = form.querySelector('[data-event-count]');
    if (counter) {
        let count = filterEventsList(true);
        counter.innerText = `${count}`;
    } else {
        filterEventsList(false);
    }
    Array.from(form.querySelectorAll('input[type="checkbox"],input[type="radio"],input[type="text"]'), (el) => {
        el.addEventListener('input', (ev) => {
            let count = filterEventsList(!!(counter && (el.name || '') !== 'search'));
            if (counter) {
                counter.innerText = `${count}`;
            }
        });
    });
    
    let submit = form.querySelector('button[type="submit"]');
    if (submit) {
        submit.addEventListener('click', (ev) => {
            ev.preventDefault();
            filterEventsList();

            let filterAction = form.querySelector('[data-bs-toggle="collapse"]');
            if (filterAction) {
                let filterCollapse = form.querySelector(filterAction.dataset.bsTarget);
                if (filterCollapse) {
                    Collapse.getOrCreateInstance(filterCollapse).hide();
                }
            }
        });
    }
    
    let reset = form.querySelector('button[type="reset"]');
    if (reset) {
        reset.addEventListener('click', (ev) => {
            location.reload(true);
        });
    }

    let filterAction = form.querySelector('[data-bs-toggle="collapse"]');
    if (filterAction) {
        let filterCollapse = form.querySelector(filterAction.dataset.bsTarget);
        if (filterCollapse) {
            filterCollapse.addEventListener('show.bs.collapse', (event) => {
                let backdrop = document.querySelector('.navbar-backdrop');
                if (!backdrop) {
                    backdrop = document.createElement('DIV');
                    backdrop.className = 'backdrop navbar-backdrop';
                    document.body.appendChild(backdrop);
                }
                
                if (!backdrop.classList.contains('active')) {
                    setTimeout(() => backdrop.classList.add('active'), 100);
                }
            });
            filterCollapse.addEventListener('hide.bs.collapse', (event) => {
                let backdrop = document.querySelector('.navbar-backdrop');
                if (backdrop) {
                    backdrop.classList.remove('active');
                    setTimeout(() => {
                        if (!backdrop.classList.contains('active')) {
                            backdrop.remove();
                        }
                    }, 250);
                }
            });
        }
    }
};
