
type mapFn = (v: Element, k: number) => HTMLElement | any;

/**
 * Query Handler
 * @param selector The desired element selector string.
 * @param context_or_callback The context element or the forEach callback function. 
 * @param callback The forEach callback function, used when context is an element.
 * @returns
 */
function query(selector: string): HTMLElement[];
function query(selector: string, context: HTMLElement | Document): HTMLElement[];
function query(selector: string, callback: mapFn): HTMLElement[];
function query(selector: string, context_or_callback?: HTMLElement | Document | mapFn, callback?: mapFn): HTMLElement[] {
    let context;
    if (typeof context_or_callback === 'function') {
        context = document;
        callback = context_or_callback;
    } else {
        context = context_or_callback || document;
    }

    if (typeof callback === 'function') {
        return Array.from(context.querySelectorAll(selector), callback);
    } else {
        return Array.from(context.querySelectorAll(selector));
    }
}

// Export Module
export default query;
