
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import ready from "../supports/ready";
import query from "../supports/query";

async function invokeHeroParallax() {
    await ready();
    
    if (query('.hero--parallax').length === 0) {
        return;
    }

    // @todo fade out on scroll

    gsap.registerPlugin(ScrollTrigger);

    gsap.to('.js-hero-parallax', {
        scrollTrigger: {
            trigger: '.hero--parallax',
            toggleActions: "restart complete reverse reset",
            start: 'top top',
            end: "50% -50%",
            scrub: true,
            // markers: true,
        },
        translateY: 80,
        // 20%
        // scale: 1.05,
    })

}

// Export Module
export default invokeHeroParallax;
