import query from "../supports/query";
import ready from "../supports/ready";

export default async function () {
    await ready();

    const fades = query('.fadein');
    const options = {
        rootMargin: "-25% 0% -25% 0%"
    };
    const fadeObserver = new IntersectionObserver(function (entries, fadeObserver) {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.add('fadein--active');
                fadeObserver.unobserve(entry.target);
            }
        });
    }, options);
    
    fades.map(fade => {
        fade.classList.add('fadein--on')
        fadeObserver.observe(fade);
        let i = 0
        fade.querySelectorAll('img:not([src$=".svg"])').forEach(img =>{
            img.style.transitionDelay = i + "ms";
            i+=100;
        })
    });
}
