import query from "../supports/query";
import ready from "../supports/ready";
import throttle from "../supports/throttle";

class Intersection {

    /**
     * Default Instance configuration
     */
    static defaults = {
        className: 'in-view',
        offsetTop: 0,
        offsetRight: 0,
        offsetBottom: 0,
        offsetLeft: 0,
        threshold: 0
    };

    /**
     * Instance Storage
     */
    static instances = new Map;

    /**
     * Switch if Observer has been started already.
     */
    static observerStarted = false;

    /**
     * Start Observer
     * @returns Observe
     */
    static observe() {
        if (this.observerStarted) {
            return;
        }
        this.observerStarted = true;

        const callback = throttle(() => {
            for (const [el, instance] of Intersection.instances.entries()) {
                instance.handle()
            }
        }, 100);
        
        if (false && typeof window.MutationObserver !== 'undefined') {
            (new MutationObserver(callback)).observe(document.body, {
                attributes: false,
                childList: true,
                subtree: true
            });
        } else {
            ['scroll', 'resize', 'load'].forEach((event) => {
                window.addEventListener(event, callback);
            });
        }
    }

    /**
     * Get Instance.
     * @param {HTMLElement} el 
     * @returns {Intersection}
     * @throws {Error}
     */
    static getInstance(el) {
        if (Intersection.instances.has(el)) {
            return Intersection.instances.get(el);
        } else {
            throw new Error('The element has no assigned Intersection instance.');
        }
    }

    /**
     * Check if instance exists.
     * @param {HTMLElement} el 
     * @returns {boolean}
     */
    static hasInstance(el) {
        return Intersection.instances.has(el);
    }

    /**
     * Get or Create instance.
     * @param {HTMLElement} el 
     * @param {Object} config 
     * @returns {Intersection}
     */
    static getOrCreateInstance(el, config) {
        if (Intersection.instances.has(el)) {
            return Intersection.instances.get(el);
        } else {
            return new Intersection(el, config || {});
        }
    }

    /**
     * Create new Intersection instance.
     * @param {HTMLElement} root 
     * @param {Object} config 
     */
    constructor(root, config) {
        if (!root || !(root instanceof HTMLElement)) {
            throw new Error('The passed argument is not a valid HTMLElement.');
        }
        if (Intersection.instances.has(root)) {
            throw new Error('The passed element has already been registered.');
        }

        this.root = root;
        this.config = Object.assign({}, Intersection.defaults, config && typeof config === 'object' ? config : {});
        this.wasInView = false;
        Intersection.instances.set(root, this);
    }

    /**
     * Check if element is in Viewport
     */
    inViewport() {
        const { top, right, bottom, left, width, height } = this.root.getBoundingClientRect();

        const intersection = {
            t: bottom,
            r: window.innerWidth - left,
            b: window.innerHeight - top,
            l: right
        };

        const threshold = {
            x: this.config.threshold * width,
            y: this.config.threshold * height
        };

        return intersection.t > (this.config.offsetTop    + threshold.y)
            && intersection.r > (this.config.offsetRight  + threshold.x)
            && intersection.b > (this.config.offsetBottom + threshold.y)
            && intersection.l > (this.config.offsetLeft   + threshold.x);
    }

    /**
     * Handle Viewport Check
     */
    handle() {
        const inViewport = this.inViewport();

        if (inViewport && !this.root.classList.contains(this.config.className)) {
            this.root.classList.add(this.config.className);
            this.wasInView = true;

            if (this.wasInView && !this.root.classList.contains('revenant')) {
                this.root.classList.add('revenant');
            }
        } else if (!inViewport && this.root.classList.contains(this.config.className)) {
            this.root.classList.remove(this.config.className);
        }
    }

}

export default async function() {
    await ready();

    query('[data-handle="intersect"],[data-intersection]', (el) => {
        return Intersection.getOrCreateInstance(el);
    });

    Intersection.observe();
};
