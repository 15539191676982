import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import ready from "../supports/ready";
import query from "../supports/query";

export default async function () {
    await ready();
    
    if (query('.businessCta-images').length === 0) {
        return;
    }

    gsap.registerPlugin(ScrollTrigger);

    gsap.to('.js-column-1', {
        scrollTrigger: {
            trigger: '.businessCta-images',
            toggleActions: "restart complete reverse reset",
            start: '-50% top',
            end: "70% -50%",
            scrub: 1.3,
            markers: false,
        },
        translateY: 40,
    })

    gsap.to('.js-column-2', {
        scrollTrigger: {
            trigger: '.businessCta-images',
            toggleActions: "restart complete reverse reset",
            start: '-50% top',
            end: "70% -50%",
            scrub: 1.3,
            markers: false,
        },
        translateY: -40,
    })
}